// TODO: Unify this with engines
// TODO: Improve injection of config and language for this functions. They rely on default values.
import {getBoatsConstants} from '../../constants/boats';
import {formatMultiArrayForURL} from '@dmm/lib-common/lib/formatting';
import {multiFacetUrlToArray} from '../multiFacetHelper';
import {getCurrencyCode, getLength, getRadius} from '../uomHelper';
import {getDefaultSort, sortTypeMap} from '../sortHelper';
import get from 'lodash/get';
import {getCountryCodeFromURL, unslugify} from './shared';
import { getMessages } from '../../tppServices/translations/messages';

/**
 * Dynamically generate the parceReplace functions to parse arguments from url
 * @param boatConstants previously was global and called "bts()"
 * @param config
 * @param t
 * @returns {{parseReplaceLegacyMakeModelParams: ((function(string=, string=): ({multiFacetedMakeModel: *}))|*), parseReplaceKeywordParams: ((function(string=): ({keyword: string}))|*), parseReplaceHullParams: ((function(string=, *): ({hullMaterial: string}))|*), parseReplaceCurrencyParams: ((function(string=): ({}))|*), parseReplacePriceParams: ((function(string=): ({price: string, currency: *}))|*), parseReplaceFuelParams: ((function(string=, *): ({fuelType: string}))|*), parseReplaceOwnerParams: ((function(string=): ({ownerId: string}))|*), parseReplaceByParams: ((function(string=, *): ({sellerType: *}))|*), parseReplaceConditionParams: ((function(string=, *): ({condition: *}))|*), parseReplaceRepParams: ((function(string=): ({salesRepId: string}))|*), parseReplaceYearParams: ((function(string=): ({year: string}))|*), parseReplaceCountryParams: ((function(string=): ({country: string}))|*), parseReplaceRegionParams: ((function(string=, *): ({region: *}))|*), parseReplaceEngineOneHoursParams: ((function(string=): ({engineOneHours: string}))|*), parseReplaceEngineTypeParams: ((function(string=, *): ({engine: string}))|*), parseReplaceLengthParams: ((function(string=, *): ({uom: *, length: string}))|*), parseReplaceWorldRegionParams: ((function(string=, *): ({worldRegion: *}))|*), parseReplaceSortParams: ((function(string=, string=, *): ({sort}))|*), parseReplaceRadiusParams: ((function(string=, *): ({distance: string}))|*), parseReplaceCityParams: ((function(string=): ({city: string}))|*), parseReplaceEngineNumberParams: ((function(*): ({numberOfEngines: string}))|*), parseReplaceMakeModelParams: ((function(string=): ({multiFacetedMakeModel: *}))|*)}}
 */
export const parseReplacers = (boatConstants, config, t, mappedValue) => {
  const messages = getMessages();
  const parseReplaceOwnerParams = (owner = '') => {
    if (boatConstants.BRANDED_SEARCH_URL_OWNER_PATTERN.test(owner)) {
      const matches = boatConstants.BRANDED_SEARCH_URL_OWNER_PATTERN.exec(owner);
      const ownerId = matches[3];
      return {
        ownerId
        // ownerDetails: true // TODO uncomment when API supports this
      };
    }
    return {};
  };
  const parseReplaceRepParams = (rep = '') => {
    if (boatConstants.BRANDED_SEARCH_URL_REP_PATTERN.test(rep)) {
      const matches = boatConstants.BRANDED_SEARCH_URL_REP_PATTERN.exec(rep);
      const salesRepId = matches[3];
      return {
        salesRepId
      };
    }
    return {};
  };
  const parseReplaceRegionParams = (region = '') => {
    if (boatConstants.SEARCH_URL_REGION_PATTERN.test(region)) {
      const matches = boatConstants.SEARCH_URL_REGION_PATTERN.exec(region);
      return {
        region: mappedValue(matches[1], messages.regionFacet)
      };
    }
    return {};
  };
  const parseReplaceWorldRegionParams = (worldRegion = '') => {
    if (boatConstants.SEARCH_URL_WORLD_REGION_PATTERN.test(worldRegion)) {
      const matches = boatConstants.SEARCH_URL_WORLD_REGION_PATTERN.exec(worldRegion);
      return {
        worldRegion: mappedValue(matches[1], messages.worldRegionFacet)
      };
    }
    return {};
  };
  const parseReplaceCountryParams = (country = '') => {
    country = country ? getCountryCodeFromURL(country) : country;
    if (boatConstants.SEARCH_URL_COUNTRY_PATTERN.test(country)) {
      const matches = boatConstants.SEARCH_URL_COUNTRY_PATTERN.exec(country);
      return {
        country: matches[1].toUpperCase() //TODO add some validation to this data.
      };
    }
    return {};
  };
  const parseReplaceLegacyMakeModelParams = (make = '', model = '') => {
    if (boatConstants.SEARCH_URL_MAKE_PATTERN.test(make)) {
      const makeMatches = boatConstants.SEARCH_URL_MAKE_PATTERN.exec(make);
      const makeValue = unslugify(makeMatches[1], ' ', '-');
      const modelMatches = model
        ? boatConstants.SEARCH_URL_MODEL_PATTERN.exec(model)
        : '';
      let modelValue = [];
      if (modelMatches) {
        modelValue = [unslugify(modelMatches[1], ' ', '-')];
      }
      return {
        multiFacetedMakeModel: formatMultiArrayForURL([[makeValue, modelValue]])
      };
    }
    return {};
  };
  const parseReplaceMakeModelParams = (makeModel = '') => {
    if (boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.test(makeModel)) {
      const matches = boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
      return {
        multiFacetedMakeModel: formatMultiArrayForURL(
          multiFacetUrlToArray(matches[1], '+')
        )
      };
    }
    return {};
  };
  const parseReplaceYearParams = (year = '') => {
    if (boatConstants.SEARCH_URL_YEAR_PATTERN.test(year)) {
      const matches = boatConstants.SEARCH_URL_YEAR_PATTERN.exec(year);
      return {
        year: `${matches[1] || ''}-${matches[2] || ''}`
      };
    }
    return {};
  };
  const parseReplaceLengthParams = (length = '', customUom) => {
    if (boatConstants.SEARCH_URL_LENGTH_PATTERN.test(length)) {
      const matches = boatConstants.SEARCH_URL_LENGTH_PATTERN.exec(length);
      let value = matches[1];
      if (matches[2]) {
        value += `-${matches[2]}`;
      }
      return {
        length: value,
        uom: getLength(customUom)
      };
    }
    return {};
  };
  const parseReplaceEngineOneHoursParams = (engineOneHours = '') => {
    if (boatConstants.SEARCH_URL_ENGINE_HOURS_PATTERN.test(engineOneHours)) {
      const matches = boatConstants.SEARCH_URL_ENGINE_HOURS_PATTERN.exec(engineOneHours);
      let value = matches[1];
      if (matches[2]) {
        value += `-${matches[2]}`;
      }
      return {
        engineOneHours: value,
      };
    }
    return {};
  };
  const parseReplacePriceParams = (price = '') => {
    if (boatConstants.SEARCH_URL_PRICE_PATTERN.test(price)) {
      const matches = boatConstants.SEARCH_URL_PRICE_PATTERN.exec(price);
      let value = `${matches[1]}-`;
      if (matches[2]) {
        value += `${matches[2]}`;
      }

      return {
        price: value,
        currency: getCurrencyCode()
      };
    }
    return {};
  };
  const parseReplaceCurrencyParams = (currency = '') => {
    if (!currency) {
      return {};
    }

    const currencyFromParam = currency.split('-')[1];
    if (!currencyFromParam) {
      return {};
    }

    return {
      currency: currencyFromParam.toUpperCase()
    };
  };
  const parseReplaceByParams = (by = '') => {
    if (boatConstants.SEARCH_URL_BY_PATTERN.test(by)) {
      const matches = boatConstants.SEARCH_URL_BY_PATTERN.exec(by);
      return {
        sellerType: mappedValue(matches[1], messages.forSaleFacet)
      };
    }
    return {};
  };
  const parseReplaceSortParams = (sort = '', postalCode = '') => {
    const matches = boatConstants.SEARCH_URL_SORT_PATTERN.exec(sort);
    if (matches) {
      const [sortBy, sortOrder] = matches[1].split(':');
      const sortEng = `${mappedValue(sortBy, messages.search)}:${mappedValue(
        sortOrder,
        messages.sortFacet
      )}`;

      return {sort: sortTypeMap[sortEng] || 'length-desc'};
    }
    const defaultSort = getDefaultSort(postalCode);
    return {sort: sortTypeMap[defaultSort]};
  };
  const parseReplaceKeywordParams = (keyword = '') => {
    if (boatConstants.SEARCH_URL_KEYWORD_PATTERN.test(keyword)) {
      const matches = boatConstants.SEARCH_URL_KEYWORD_PATTERN.exec(keyword);
      return {
        keyword: matches[1]
      };
    }
    return {};
  };
  const parseReplaceConditionParams = (condition = '') => {
    const matches = boatConstants.SEARCH_URL_CONDITION_PATTERN.exec(condition);
    if (matches) {
      return {condition: mappedValue(matches[1], messages.conditionFacet)};
    }
    return {};
  };
  const parseReplaceEngineTypeParams = (engine = '') => {
    if (boatConstants.SEARCH_URL_ENGINETYPE_PATTERN.test(engine)) {
      const decodedEngineTypes = [];
      const matches = boatConstants.SEARCH_URL_ENGINETYPE_PATTERN.exec(engine);
      const engineTypes = matches[1];

      engineTypes.split('+').map((engineType) => {
        engineType = mappedValue(engineType, messages.engineFacetText);
        decodedEngineTypes.push(engineType);
      });
      return {
        engine: decodedEngineTypes.join()
      };
    }
    return {};
  };
  const parseReplaceFuelParams = (fuelType = '') => {
    if (boatConstants.SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
      const decodedFuelTypes = [];
      const matches = boatConstants.SEARCH_URL_FUEL_PATTERN.exec(fuelType);
      const fuelTypes = matches[1];

      fuelTypes.split('+').map((fuel) => {
        fuel = mappedValue(fuel, messages.fuelTypeFacet);
        const mappedFuel = getBoatsConstants().FUEL_TYPE_MAPPING.api[fuel]
          ? getBoatsConstants().FUEL_TYPE_MAPPING.api[fuel]
          : fuel;
        decodedFuelTypes.push(mappedFuel);
      });
      return {
        fuelType: decodedFuelTypes.join()
      };
    }
    return {};
  };
  const parseReplaceHullParams = (hullMaterial = '') => {
    if (boatConstants.SEARCH_URL_HULL_PATTERN.test(hullMaterial)) {
      const decodedHullMaterials = [];
      const matches = boatConstants.SEARCH_URL_HULL_PATTERN.exec(hullMaterial);

      let hullValue = matches[1];
      hullValue.split('+').map((material) => {
        material = mappedValue(material, messages.hullMaterialFacet);
        const materialFacet = getBoatsConstants().HULL_MATERIAL_MAPPING.api[
          material
          ]
          ? getBoatsConstants().HULL_MATERIAL_MAPPING.api[material]
          : material;
        decodedHullMaterials.push(materialFacet);
      });
      return {
        hullMaterial: decodedHullMaterials.join()
      };
    }
    return {};
  };
  const parseReplaceCityParams = (city = '') => {
    if (boatConstants.SEARCH_URL_CITY_PATTERN.test(city)) {
      const matches = boatConstants.SEARCH_URL_CITY_PATTERN.exec(city);
      let cityValue = unslugify(matches[1], '-', ' ').split('+');
      return {
        city: cityValue.join(',')
      };
    }
    return {};
  };
  const parseReplaceEngineNumberParams = (numberOfEngines) => {
    const matches = boatConstants.SEARCH_URL_ENGINE_NUMBER_PATTERN.exec(numberOfEngines);
    if (matches) {
      return {numberOfEngines: matches[1]};
    }
    return {};
  };
  const parseReplaceRadiusParams = (radius = '') => {
    if (boatConstants.SEARCH_URL_RADIUS_PATTERN.test(radius)) {
      const matches = boatConstants.SEARCH_URL_RADIUS_PATTERN.exec(radius);
      let radiusVal = matches[1];
      if (radiusVal === t(messages.radiusFacet.any)) {
        return {distance: `4000${getRadius()}`};
      } else if (radiusVal === t(messages.radiusFacet.exact)) {
        radiusVal = messages.radiusFacet.exact.defaultMessage;
        return {distance: `${radiusVal}`};
      } else if (radiusVal !== '0') {
        const radiusSearch = get(
          config,
          'supports.radiusSearch.enabled',
          false
        );
        const radiusParams = {distance: radiusVal + getRadius()};
        if (radiusSearch) {
          radiusParams.radius = true;
        }
        return radiusParams;
      }
    }

    return {distance: `200${getRadius()}`};
  };
  return {
    parseReplaceOwnerParams,
    parseReplaceRepParams,
    parseReplaceRegionParams,
    parseReplaceWorldRegionParams,
    parseReplaceCountryParams,
    parseReplaceLegacyMakeModelParams,
    parseReplaceMakeModelParams,
    parseReplaceYearParams,
    parseReplaceLengthParams,
    parseReplaceEngineOneHoursParams,
    parseReplacePriceParams,
    parseReplaceCurrencyParams,
    parseReplaceByParams,
    parseReplaceSortParams,
    parseReplaceKeywordParams,
    parseReplaceConditionParams,
    parseReplaceEngineTypeParams,
    parseReplaceFuelParams,
    parseReplaceHullParams,
    parseReplaceCityParams,
    parseReplaceEngineNumberParams,
    parseReplaceRadiusParams
  };
};

