/* istanbul ignore next */
import { isMobileResolution, isTabletOrSmallLaptopResolution } from '../utils/commonHelper';
import { isNode } from './detectEnv';

/* istanbul ignore next */
const nil = () => {};

const DEVICES = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  unknown: 'unknown'
};

/* istanbul ignore next */
const cfDetectDevice = (headers = {}) => {
  if (String(headers['cloudfront-is-tablet-viewer']) === 'true') {
    return DEVICES.tablet;
  }
  if (String(headers['cloudfront-is-mobile-viewer']) === 'true') {
    return DEVICES.mobile;
  }
  if (String(headers['cloudfront-is-desktop-viewer']) === 'true' || String(headers['cloudfront-is-smarttv-viewer']) === 'true') {
    return DEVICES.desktop;
  }
  return DEVICES.unknown;
};

const expressDetectDevice = () => {
  let detectedDevice = null;
  const cfDeviceDetector = (req) => {
    if (detectedDevice && !req) {
      return detectedDevice;
    }
    detectedDevice = cfDetectDevice(req?.headers);
    return detectedDevice;
  };
  return cfDeviceDetector;
};

const expressDeviceDetector = expressDetectDevice();


const clientDeviceDetector = () => {
  if (isMobileResolution()) {
    return DEVICES.mobile;
  }
  if (isTabletOrSmallLaptopResolution()) {
    return DEVICES.tablet;
  }
  return DEVICES.desktop;
};


export const deviceDetector = () => {
  if (isNode()) {
    return expressDeviceDetector;
  }
  return clientDeviceDetector;
};

const detectDevice = deviceDetector();

const debuggedRequest = () => {
  let debugReq = {};
  const getDebugRequest = () => {
    return debugReq;
  };
  /* istanbul ignore next */
  const setDebugRequest = (req) => {
    debugReq = req;
  };
  return {getDebugRequest, setDebugRequest};
};
const {getDebugRequest, setDebugRequest} = debuggedRequest();

/* istanbul ignore next */
const emitterServerSide = (emitter) => {
  let theListener = nil;
  return () => ({
    dispatchEvent: (event, data) => {
      emitter.emit(event, {detail: data});
    },
    on: (event, callback) => {
      theListener = callback;
      emitter.on(event, callback);
    },
    off: (event, callback) => {
      emitter.off(event, callback || theListener);
    },
    emitter
  });
};

/* istanbul ignore next */
const emitterClientSide = (emitter) => {
  let theListener = nil;
  return () => ({
    dispatchEvent: (eventName, data) => {
      const event = new CustomEvent(eventName, { detail: data });
      emitter.dispatchEvent(event);
    },
    on: (eventName, listener) => {
      theListener = listener;
      emitter.addEventListener(eventName, listener);
    },
    off: (eventName, callback) => {
      emitter.removeEventListener(eventName, callback || theListener);
    },
    emitter
  });
};

const safeProcess = () => typeof process !== 'undefined' ? process : {};
/* istanbul ignore next */
const safeWindow = () => (typeof window !== 'undefined' ? window : {});


/**
 * Get the portal name from the environment variables.
 * process.env.PORTAL_NAME -> used in the server side
 * window.__PORTAL_NAME__ -> used in the client side
 * process.env.REACT_APP_PORTAL_NAME -> used in the client side during development
 */
const getPortalName = () => {
  const portal = process.env.PORTAL_NAME || safeWindow().__PORTAL_NAME__ || process.env.REACT_APP_PORTAL_NAME || '';
  if (!portal) {
    throw new Error('No portal name available');
  }
  return portal;
};

/* istanbul ignore next */
const eventEmitter = () => {
  if (isNode()) {
    const { EventEmitter } = require('events');
    const emitter = new EventEmitter();
    return emitterServerSide(emitter);
  } else if (typeof window !== 'undefined') {
    return emitterClientSide(window);
  }
};

const createMemo = () => {
  const memoStore = {};
  const memo = (memoKey, memoObject) => {
    if (memoKey) {
      if (memoObject) {
        // Should we throw an error if the key already exists?
        memoStore[memoKey] = memoObject;
        return;
      }
      return memoStore[memoKey];
    }
  };
  const deleteMemo = (memoKey) => {
    if (memoKey) {
      delete memoStore[memoKey];
    }
  };
  return {memoTpp: memo, deleteMemoTpp: deleteMemo};
};

const {memoTpp, deleteMemoTpp} = createMemo();

// This is the default key for the container.
// Even though is not currently prepared, the base has been set to be able to change portal on the fly during development.
// By using several container keys we could just force the container to be reloaded with the new key and the new portal.
const TPP_CONTAINER = 'TPP_CONTAINER';

// Useful to change di container globally.
// Easier to manage the hard dependency injection required than to rewrite everything to
// allow a container to be passed as a parameter.
const globalKeyManager = () => {
  let defaultContainerKey = TPP_CONTAINER;
  const setTPPContainerKey = (containerKey) => {
    if (typeof containerKey !== 'string') {
      throw new Error('Container key must be a string');
    }
    const tmpTpp = memoTpp(containerKey);
    if (!tmpTpp?.started()) {
      throw new Error('You must create and start a TPP container before set its key as default');
    }
    defaultContainerKey = containerKey;
  };

  const clearTPPKey = () => {
    defaultContainerKey = TPP_CONTAINER;
  };

  const getTPPContainerKey = () => defaultContainerKey;

  return {setDefaultTPPContainerKey: setTPPContainerKey, getTPPContainerKey, clearTPPKey};
};

const pathNameFromHistory = (history) => {
  let serverPathname = history?.location?.pathname || '';
  let clientPathname = history?.pathname || '';
  return serverPathname || clientPathname;
};

// We want to be able to debug the requests in the logs.
// So we need a way to find in the logs the request that we are debugging.
// This is a quick and dirty way to allow logging using urls that match some regex.
// since we are able to pass any argument we want and React will not complain about it.
const URL_LOG_REGEX = /log-debug-url/;

const URL_LOG_STATUS = {
  ENABLED: 1,
  DISABLED: 0,
  DEFAULT_URL_MATCH: -1
};

/* istanbul ignore next */
const urlLoggerEnabler = () => {
  // disable url logging by default
  let URL_LOG_ENABLED = false;
  let ORIGINAL_URL = '';
  let REQ_UUID = 'not-set';
  /**
   * Enable or disable the url log. If we pass a boolean we will enable or disable the log.
   * @param logStatus: flag to enable or disable the log
   * - URL_LOG_STATUS.ENABLED: enable the log
   * - URL_LOG_STATUS.DISABLED: disable the log
   * - URL_LOG_STATUS.DEFAULT_URL_MATCH: enable the log if the URL contains the URL_LOG_REGEX
   */
  const enableUrlLog = (logStatus) => {
    if (URL_LOG_STATUS.ENABLED === logStatus || URL_LOG_STATUS.DISABLED === logStatus) {
      URL_LOG_ENABLED = !!logStatus;
      if (URL_LOG_STATUS.ENABLED === logStatus) {
        REQ_UUID = Math.random();
        const debugReq = typeof window !== 'undefined' ? { originalUrl: window.location.href } : getDebugRequest();
        REQ_UUID = Math.random();
        ORIGINAL_URL = debugReq.originalUrl || '';
      }
      return;
    }
    if (URL_LOG_STATUS.DEFAULT_URL_MATCH === logStatus) {
      // we allow url debugging by default if we find the regex URL_LOG_REGEX in the URL
      const debugReq = typeof window !== 'undefined' ? { originalUrl: window.location.href } : getDebugRequest();
      REQ_UUID = Math.random();
      ORIGINAL_URL = debugReq.originalUrl || '';
      const defaultLogEnable = URL_LOG_REGEX.test(debugReq.originalUrl);
      URL_LOG_ENABLED = defaultLogEnable;
    }
  };
  const urlLogEnabled = () => URL_LOG_ENABLED;
  const getDebugUrl = () => ORIGINAL_URL;
  const getReqUuid = () => REQ_UUID;
  return {enableUrlLog, urlLogEnabled, getDebugUrl, getReqUuid};
};

/**
 * Using these functions we can log both client side and server side with a custom url.
 * If we want to enable login we should call enableUrlLog. If we call it from a express middleware
 * we will enable the log for the server side with URL_LOG_REGEX as default.
 * That way, any url that matches URL_LOG_REGEX will be logged.
 * The default behaviour looks for the URL_LOG_REGEX in the URL.
 */
const {
  /**
   * Enable or disable the url log. If we pass a boolean we will enable or disable the log.
   * If we don't pass any parameter, we will enable the log if the URL contains the URL_LOG_REGEX
   */
  enableUrlLog,
  /**
   * Check if the url log is enabled
   */
  urlLogEnabled,
  /**
   * Get the original URL from the request.
   * This will only work if we call enableUrlLog without parameters.
   */
  getDebugUrl,
  /**
   * Get the request UUID
   */
  getReqUuid
} = urlLoggerEnabler();

/**
 * Using this function we can log both client side and server side with a custom url.
 * The default behaviour looks for the URL_LOG_REGEX in the URL.
 * So we could use it like this: https://<some-url>?log-debug-url=aaa.
 * Then, we could look in CloudWatch for the messages searching for the pattern log-debug-url=aaa
 * We can log with an extra regex on the URL.
 * Either we could use the default URL_LOG_REGEX or append a new string to use as regex
 * i.e. https://<some-url>?log-debug-url=aaa?someotherstuff=bbb. and call logWithUrlParam(/someotherstuff=aaa/, 'message to print')
 *
 * Let's say we want to debug the homepage by default. We should go to the url:
 * https://qa.yachtworld.com?log-debug-url=homepage
 * Then we could use the following code:
 * logWithUrlParam(/homepage/, 'Debugging the homepage')
 * @param reUrl: regular expression to match the url
 * @param messageToPrint: message to print
 */
/* istanbul ignore next */
const logWithUrlParam = (reUrl, messageToPrint) => {
  const enabledLogging = urlLogEnabled();
  if (!enabledLogging) {
    return;
  }
  const originalUrl = getDebugUrl();
  if (enabledLogging || originalUrl.match(reUrl)) {
    const requestRandomId = getReqUuid();
    const debugMessage = {
      level: 'debug',
      matchUrl: reUrl,
      timeStamp: new Date().toISOString(),
      originalUrl,
      message: messageToPrint,
      requestRandomId
    };
    /* eslint-disable no-console */
    console.log(JSON.stringify(debugMessage));
  }
};

const {setDefaultTPPContainerKey, getTPPContainerKey, clearTPPKey} = globalKeyManager();

const CONFIG_STARTED = 'CONFIG_STARTED';
const CONFIG_ERROR = 'CONFIG_ERROR';
const TPP_STORED = 'TPP_STORED';
const TPP_STARTED = 'TPP_STARTED';
const CONFIG_SERVICE = 'configService';
const LANGUAGE_SERVICE = 'languageService';
const tppEventEmitter = eventEmitter();

export {
  createMemo,
  memoTpp,
  deleteMemoTpp,
  CONFIG_STARTED,
  TPP_STORED,
  TPP_STARTED,
  CONFIG_ERROR,
  tppEventEmitter,
  CONFIG_SERVICE,
  LANGUAGE_SERVICE,
  setDefaultTPPContainerKey,
  getTPPContainerKey,
  clearTPPKey,
  TPP_CONTAINER,
  safeProcess,
  pathNameFromHistory,
  getPortalName,
  detectDevice,
  getDebugRequest,
  setDebugRequest,
  DEVICES,
  logWithUrlParam,
  enableUrlLog,
  URL_LOG_STATUS
};
